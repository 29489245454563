<template>
	<section class="col-12">
		<div class="row">
			<div class="col-auto p-0">
				<h3>
					<b>{{ $t('global.title.pension') }}</b>
				</h3>
			</div>
		</div>
		<Field>
			<template slot="title">
				{{ $t('companyPension.oblfirmaandel') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.mandatoryCompanyShare.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.mandatoryCompanyShare.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputNumber v-model="pension.mandatoryCompanyShare.value" :disabled="disabled" />
				<InputDropdownValuta class="mt-1" v-model="pension.mandatoryCompanyShare.type" :disabled="disabled" :required="true" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.oblmedarbandel') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.mandatoryEmployeeShare.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.mandatoryEmployeeShare.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputNumber v-model="pension.mandatoryEmployeeShare.value" :disabled="disabled" />
				<InputDropdownValuta class="mt-1" v-model="pension.mandatoryEmployeeShare.type" :disabled="disabled" :required="true" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.deakningsgivende') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.isBeneficial.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.isBeneficial.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputCheck v-model="pension.isBeneficial.value" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.ikraftdato') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.startDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.startDate.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDate v-model="pension.startDate.value" :disabled="disabled" />
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.expirationDate') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.expirationDate.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.expirationDate.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown
					class="mt-1"
					v-model="pension.expirationDate.value"
					:options="['65', '67', 'Folkepension']"
					:disabled="disabled"
					:placeholder="'Udløbsdato'"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.waitingPeriode') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.waitingPeriode.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.waitingPeriode.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown
					v-model="pension.waitingPeriode.value"
					:options="['Straks', '3 måneder', '6 måneder', '9 måneder']"
					:disabled="disabled"
					:placeholder="'Periode'"
				/>
			</template>
		</Field>

		<Field>
			<template slot="title">
				{{ $t('companyPension.drawingWindow') }}
			</template>
			<template slot="supplierComment" v-if="supplierPensionTemplate">
				<Comment v-model="supplierPensionTemplate.drawingWindow.comment" :placeholder="'Leverandør kommentar'" :disabled="true" />
			</template>
			<template slot="comment">
				<Comment v-model="pension.drawingWindow.comment" :disabled="disabled" />
			</template>
			<template slot="comp">
				<InputDropdown v-model="pension.drawingWindow.value" :options="['3 måneder', '6 måneder']" :disabled="disabled" :placeholder="'Periode'" />
			</template>
		</Field>
	</section>
</template>

<script>
import Field from '@/modules/global/atomComponents/Field.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import InputDate from '@/modules/global/atomComponents/InputDate.vue'
import InputCheck from '@/modules/global/atomComponents/InputCheck.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown.vue'
import InputDropdownValuta from '@/modules/global/atomComponents/InputDropdownValuta.vue'
import Comment from '@/modules/global/atomComponents/InputComment.vue'

export default {
	name: 'CompanyPension',
	components: {
		Field,
		InputNumber,
		Comment,
		InputDropdownValuta,
		InputDate,
		InputCheck,
		InputDropdown,
	},
	props: {
		value: Object,
		pensionIndex: Number,
		disabled: Boolean,
	},
	data() {
		return {}
	},
	computed: {
		supplierPensionTemplate() {
			if (this.pension.supplierTemplateId) {
				var suppliers = this.$store.getters['supplier/suppliers']
				var supplierId = this.pension.supplierId
				var supplier = suppliers.find((supplier) => supplier.id == supplierId)
				return supplier.groupTemplates.find((template) => template.id == this.pension.supplierTemplateId).pension
			} else {
				return null
			}
		},
		pension: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
	},
}
</script>

<style></style>
